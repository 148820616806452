import React from "react"
import {
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

export default class AppBar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <div>
        <Navbar color="transparent" light expand="md">
          <NavbarBrand href="/">
            <span className="logo">
              <img
                src="https://res.cloudinary.com/pgoodjohn/image/upload/v1507573028/MattiaSerchione/Assets/logo.png"
                height="50"
                alt={this.props.siteTitle}
              />
            </span>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/music/">Music</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="/graphics/">Graphics</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink href="/about/">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact/">Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}
