import React from "react"
import { Container, Row, Col } from "reactstrap"
import { SocialIcon } from "react-social-icons"

var styles = {
  centerColumn: {
    textAlign: "center",
  },
  leftColumn: {
    textAlign: "left",
  },
  rightColumn: {
    textAlign: "right",
  },
  socialIcon: {
    margin: "5px",
  },
}

export default class Footer extends React.Component {
  render() {
    return (
      <Container fluid={true}>
        <Row>
          <Col md={4} style={styles.leftColumn} className="xs-hidden"></Col>
          <Col md={4} style={styles.centerColumn}>
            <br />
            <p className="text-muted">
              <br />© {new Date().getFullYear()}
            </p>
          </Col>
          <Col md={4} style={styles.rightColumn}>
            <SocialIcon
              style={styles.socialIcon}
              url="https://www.facebook.com/joetomdylan"
            />
            <SocialIcon
              style={styles.socialIcon}
              url="https://www.instagram.com/mattiaserchione"
            />
            <SocialIcon
              style={styles.socialIcon}
              url="https://soundcloud.com/user-708347806"
            />
            <SocialIcon
              style={styles.socialIcon}
              url="https://it.linkedin.com/in/mattia-serchione"
            />
          </Col>
        </Row>
      </Container>
    )
  }
}
